import React from 'react';
import T from 'prop-types';
import { createRoot } from 'react-dom/client';

import Slide from './Slide';
import Carousel3D from '../../../../components/Carousel3D';

const config = data => ({
  infinite: true,
  variableWidth: false,
  centerMode: false,
  centerPadding: '0px',
  autoplay: true,
  autoplaySpeed: 6000,
  fade: true,
  arrows: false,
  pauseOnHover: false,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        dots: false,
        centerMode: true,
      },
    },
  ],
  appendDots: dots => (
    <div
      style={{
        width: '100%',
        height: '195px',
      }}
    >
      <ul style={{ width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        borderRight: '1px solid #E1E5EB',
        borderLeft: '1px solid #E1E5EB' }}
      > {dots}
      </ul>
    </div>
  ),
  customPaging: i => (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="progressBar">
        <span style={{ width: '100%' }}><span className="progress" style={{ backgroundColor: data[i].brand_color }} /></span>
      </div>
      <div className="icon-box">
        <img className="color-logo" src={data[i].color_logo_src} alt="color-icon" />
        <img className="gray-logo" src={data[i].gray_logo_src} alt="gray-icon" />
      </div>
    </div>
  ),
});

const AttributionSlider = ({ data }) => (
  <Carousel3D
    focusOnLoad
    className="attribution-hms-carousel3d"
    config={config(data)}
    slides={data.map(slide => (
      <Slide
        key={slide.id}
        data={slide}
      />
    ))}
  />
);
AttributionSlider.propTypes = {
  data: T.array,
};

export default AttributionSlider;

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('attribution-slider-root');

  if (rootNode) {
    const slides = [];
    rootNode.querySelectorAll('.attribution-slide').forEach((item) => {
      slides.push(JSON.parse(item.dataset.slide));
    });
    const root = createRoot(rootNode);
    root.render(<AttributionSlider data={slides} />);
  }
});

// Slide fields
// id
// quote_html
// author
// link_html
// brand_color
// color_logo_src
// gray_logo_src
