import React from 'react';
import T from 'prop-types';
import SimpleFormat from '@16g/react-simple-format';
import useMobileView from '../../сompetitive-intelligence-reports/shared/hooks/useMobileView';

const Slide = ({
  data,
}) => {
  const isMobileView = useMobileView();

  return (
    <div className="attribution-slide-content">
      {isMobileView && <div className="logo-box"><img className="logo" src={data.color_logo_src} alt="icon-logo" /></div>}
      <div className="quote" dangerouslySetInnerHTML={{ __html: data.quote_html }} />
      <p className="author"><SimpleFormat text={data.author} /></p>
      <div dangerouslySetInnerHTML={{ __html: data.link_html }} />
    </div>
  );
};

Slide.propTypes = {
  data: T.object.isRequired,
};

export default Slide;
